@import '../../../theme/colors';

.container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

.content {
  position: sticky;
  top: 0;
  bottom: 0;
  height: 100%;
  max-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.line {
  stroke: $primary;
  stroke-width: 3px;
  width: 24px;
  height: 50px;
  animation: pulse 0.25s  ease-out alternate infinite;

  &:nth-of-type(2n) {
    animation-delay: -0.25s;
  }
}

@keyframes pulse {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0.33);
  }
}
