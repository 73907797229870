@import '../../../theme/colors';
@import '../../../theme/sizes';

.container {
  flex: 1;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
}
