@import 'colors';

@mixin fontFamily {
  font-family: 'proxima-nova', 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

@mixin font10 {
  font-size: 10px;
  color: $text-input;
}

@mixin font13 {
  font-size: 13px;
  color: $text-label;
}

@mixin font13secondary {
  font-size: 13px;
  color: $text-secondary;
}

@mixin font14 {
  font-size: 14px;
  color: $text-secondary;
}

@mixin font16 {
  font-size: 16px;
  font-weight: 700;
  color: $text-secondary;
}

@mixin font20 {
  font-size: 20px;
  font-weight: 700;
  color: $text-secondary;
}

@mixin font24 {
  font-size: 24px;
  font-weight: 700;
}

@mixin font32 {
  font-size: 32px;
}

body {
  @include font13;
  * {
    @include fontFamily;
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text;
}
