@import 'colors';

body {
  background-color: $background-body;
  overflow-x: hidden;
}

body,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1em;
  line-height: 1.25;
  margin: 0;
  padding: 0;
  font-weight: 500;
}

a {
  color: inherit;
  text-decoration: inherit;
}

svg {
  overflow: visible;
}

circle,
path,
ellipse,
line,
polygon,
polyline,
rect {
  vector-effect: non-scaling-stroke;
}
