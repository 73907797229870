$text: var(--text);
$text-inverse: var(--text-inverse);
$text-secondary: var(--text-secondary);
$text-input: var(--text-input);
$text-label: var(--text-label);

$primary: var(--primary);
$primary-dimmed: var(--primary-dimmed);
$secondary: var(--secondary);
$secondary-dimmed: var(--secondary-dimmed);
$dark-secondary: var(--dark-secondary);
$dark: var(--dark);
$dark-dimmed: var(--dark-dimmed);

$border-primary: var(--border-primary);
$border-secondary: var(--border-secondary);
$border-dark: var(--border-dark);

$background-body: var(--background-body);
$background-input: var(--background-input);
$background: var(--background);

$success: var(--success);
$warning: var(--warning);
$danger: var(--danger);

// data viz
$delivered: #bbd043;
$delivered-dimmed: #dff0d8;
$enroute: #fae687;
$enroute-dimmed: #fcf8e3;
$failed: #f04d0c;
$failed-dimmed: #f2dede;
$outgoing: #bbd043;
$incoming: #eabc07;
$sum: $primary;
